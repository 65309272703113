<template>
  <content-with-sidebar class="blog-wrapper">

    <!-- blogs -->
    <b-row
      v-if="blogList.length"
      class="blog-list-wrapper"
    >
      <b-col
        v-for="item in blogList"
        ref="refListTable"
        :key="item.id"
        md="6"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link :to="{ name: 'posts-show', params: { slug: item.slug } }">
            <b-img
              :src="$options.filters.mediaUrl(item, 'avatar', '800x550')"
              class="card-img-top"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'posts-show', params: { slug: item.slug } }"
                class="blog-title-truncate text-body-heading"
                v-text="$options.filters.transl(item, 'title')"
              />
            </b-card-title>
            <div class="my-1 py-25">
              <b-link
                v-for="(tag,index) in item.categories"
                :key="index"
              >
                <b-badge
                  pill
                  class="mr-75"
                  variant="light-primary"
                >
                  {{ $options.filters.transl(tag, 'title') }}
                </b-badge>
              </b-link>
            </div>
            <b-card-text class="blog-content-truncate">
              {{ $options.filters.transl(item, 'excerpt') }}
            </b-card-text>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <small class="text-muted">{{ item.created }}</small>
              <b-link
                :to="{ name: 'posts-show', params: { slug: item.slug } }"
                class="font-weight-bold"
                v-text="$t('blog.item.read')"
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- pagination -->
      <b-col cols="12">
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <!--/ blogs -->

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    >
      <Sidebar
        :blog-sidebar.sync="blogSidebar"
        :categories.sync="categories"
      />
    </div>
    <!--/ sidebar -->
  </content-with-sidebar>
</template>

<script>
import _ from 'lodash'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import GlobalMixin from '@mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Sidebar from '@/views/blog/Sidebar'

export default {
  components: {
    ContentWithSidebar,
    Sidebar,
  },
  mixins: [GlobalMixin],
  metaInfo: {
    title: 'Блог | TeLeAds — біржа реклами в Телеграм',
    meta: [
      { vmid: 'description', name: 'description', content: 'Корисна інформація про роботу з біржею, розміщення реклами, написання постів та багато іншого ✈️ TeLeAds' },
    ],
  },
  data() {
    return {
      blogSidebar: [],
      categories: [],
      blogList: [],
      currentPage: 1,
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 12,
        to: 1,
        total: 1,
      },
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.debFetchData(this)
      },
      deep: true,
      immediate: true,
    },
    currentPage: {
      handler(page) {
        const params = {
          ...this.$route.query,
          ...{ page },
        }
        this.$router.push({ name: 'posts-index', query: params })
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/promo/posts/popular')
      .then(response => {
        this.blogSidebar = response.data.data
      })

    await this.$http.get('/api/promo/categories', {
      params: {
        type: 'post',
        status: 'enabled',
      },
    })
      .then(response => {
        this.categories = response.data.data
      })
  },
  methods: {
    fetchData() {
      this.$http.get('/api/promo/posts/', {
        params: {
          query: this.$route.query.query ?? null,
          category_id: this.$route.query.category_id ?? null,
          page: this.$route.query.page ?? 1,
          per_page: this.meta.per_page,
        },
      })
        .then(response => {
          this.blogList = response.data.data
          this.meta = response.data.meta
          this.currentPage = response.data.meta.current_page
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    debFetchData: _.debounce(vm => {
      vm.fetchData()
    }, 350),
  },
}
</script>
