<template>
  <div>
    <!-- input search -->
    <b-form-group class="blog-search">
      <b-input-group class="input-group-merge">
        <b-form-input
          class="d-inline-block"
          :placeholder="$t('market.table.settings.search') + '...'"
          @input="(val) => initSearch(val)"
        />
        <b-input-group-append
          class="cursor-pointer"
          is-text
        >
          <feather-icon
            icon="SearchIcon"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <!--/ input search -->

    <!-- links -->
    <div class="blog-categories mt-3">
      <h6
        class="section-label mb-1"
        v-text="$t('blog.sidebar.links')"
      />

      <div class="d-flex justify-content-start align-items-center mb-75">
        <a
          href="https://t.me/TeLeAds_UA"
          target="_blank"
        >
          <b-avatar
            rounded
            size="32"
            variant="light-primary"
            class="mr-75"
          >
            <feather-icon
              icon="SendIcon"
              size="16"
            />
          </b-avatar>
        </a>
        <a
          href="https://t.me/TeLeAds_UA"
          target="_blank"
        >
          <div
            class="blog-category-title text-body"
            v-text="$t('blog.sidebar.tg_channel')"
          />
        </a>
      </div>

      <div class="d-flex justify-content-start align-items-center mb-75">
        <a
          href="https://t.me/TeLeDive_UA"
          target="_blank"
        >
          <b-avatar
            rounded
            size="32"
            variant="light-primary"
            class="mr-75"
          >
            <feather-icon
              icon="SendIcon"
              size="16"
            />
          </b-avatar>
        </a>
        <a
          href="https://t.me/TeLeDive_UA"
          target="_blank"
        >
          <div
            class="blog-category-title text-body"
            v-text="$t('blog.sidebar.tg_blog')"
          />
        </a>
      </div>
    </div>
    <!--/ links -->

    <!-- recent posts -->
    <div class="blog-recent-posts mt-3">
      <h6
        class="section-label mb-75"
        v-text="$t('blog.sidebar.posts')"
      />
      <b-media
        v-for="(recentpost,index) in blogSidebar"
        :key="recentpost.id"
        no-body
        :class="index? 'mt-2':''"
      >
        <b-media-aside class="mr-2">
          <b-link :to="{ name: 'posts-show', params:{ slug :recentpost.slug } }">
            <b-img
              :src="$options.filters.mediaUrl(recentpost, 'avatar', '150x150')"
              width="100"
              rounded
              height="100"
            />
          </b-link>
        </b-media-aside>
        <b-media-body>
          <h6 class="blog-recent-post-title">
            <b-link
              :to="{ name: 'posts-show', params:{ slug :recentpost.slug } }"
              class="text-body-heading"
            >
              {{ $options.filters.transl(recentpost, 'title') }}
            </b-link>
          </h6>
          <span class="text-muted mb-0">
            {{ recentpost.created }}
          </span>
        </b-media-body>
      </b-media>
    </div>
    <!--/ recent posts -->

    <!-- categories -->
    <div class="blog-categories mt-3">
      <h6
        class="section-label mb-1"
        v-text="$t('blog.sidebar.categories')"
      />

      <div class="d-flex justify-content-start align-items-center mb-75">
        <b-link :to="{ name: 'posts-index', query: { category_id: 0 } }">
          <b-avatar
            rounded
            size="32"
            variant="light-info"
            class="mr-75"
          >
            <feather-icon
              icon="BookmarkIcon"
              size="16"
            />
          </b-avatar>
        </b-link>
        <b-link :to="{ name: 'posts-index', query: { category_id: 0 } }">
          <div
            class="blog-category-title text-body"
            v-text="$t('blog.sidebar.all')"
          />
        </b-link>
      </div>
      <div
        v-for="category in categories"
        :key="category.slug"
        class="d-flex justify-content-start align-items-center mb-75"
      >
        <b-link
          :to="{ name: 'posts-index', query: { category_id: category.id } }"
        >
          <b-avatar
            rounded
            size="32"
            variant="light-info"
            class="mr-75"
          >
            <feather-icon
              :icon="category.icon ? category.icon : 'BookmarkIcon'"
              size="16"
            />
          </b-avatar>
        </b-link>
        <b-link
          :to="{ name: 'posts-index', query: { category_id: category.id } }"
        >
          <div class="blog-category-title text-body">
            {{ $options.filters.transl(category, 'title') }}
          </div>
        </b-link>
      </div>
    </div>
    <!--/ categories -->
  </div>
</template>

<script>
import _ from 'lodash'
import GlobalMixin from '@mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  props: {
    blogSidebar: {
      type: [Array, null],
      default: null,
    },
    categories: {
      type: [Array, null],
      default: null,
    },
  },
  methods: {
    initSearch(search) {
      this.searchPosts(search, this)
    },
    searchPosts: _.debounce((search, vm) => {
      vm.$router.push({ name: 'posts-index', query: { query: search } })
    }, 350),
  },
}
</script>
